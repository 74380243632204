import React, { useState, useEffect } from "react";
import "./TopMenu.scss";
import { useNavigate } from "react-router-dom";
import SettingsModal from './SettingsModal';
import { useUser } from '../../context/UserContext';
import Popover from './Popover';

const TopMenu = ({
  genderFilter,
  handleFilterChange,
  selectedStyle,
  scrollToSelectedStyle,
  logoImage,
  isFullscreen,
  toggleFullscreen,
  styleGroups,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const { balance, updateBalance } = useUser();

  const displayBalance = balance !== undefined && balance !== null ? balance : '...';

  useEffect(() => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    
    if (token && username) {
      setCurrentUser({ login: username });
    } else {
      setCurrentUser(null);
    }
  }, []);

  useEffect(() => {
    const loadBalance = async () => {
      try {
        await updateBalance();
      } catch (error) {
        console.error('Ошибка при загрузке баланса:', error);
      }
    };

    loadBalance();
  }, [updateBalance]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
    setIsPopoverOpen(false);
  };

  const handleButtonClick = (filter) => {
    if (genderFilter === filter) {
      handleFilterChange("all");
    } else {
      handleFilterChange(filter);
    }
  };

  useEffect(() => {
    console.log('isPopoverOpen state changed:', isPopoverOpen);
  }, [isPopoverOpen]);

  useEffect(() => {
    const button = document.querySelector('.settings-button');
    console.log('Button classes:', button?.className);
    console.log('isPopoverOpen:', isPopoverOpen);
  }, [isPopoverOpen]);

  return (
    <div className="top-menu-wrapper">
      <div className="top-menu">
        <div className="left-section">
          <div
            className="selected-style"
            onClick={selectedStyle ? scrollToSelectedStyle : undefined}
            title={selectedStyle}
          >
            <img
              src={selectedStyle ? selectedStyle.styleImage : logoImage}
              alt={selectedStyle}
              className="selected-style-image"
            />
            <div className="selected-style-info">
              {selectedStyle ? (
                <>
                  <span className="selected-style-prefix">
                    Портрет в стиле:
                  </span>
                  <span className="selected-style-group">
                    {selectedStyle.groupName.charAt(0).toUpperCase() +
                      selectedStyle.groupName.slice(1).toLowerCase()}
                  </span>
                </>
              ) : (
                <span className="selected-style-group">
                
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="middle-section">{/* Пустое пространство */}</div>
        <div className="right-section">
          <div className="filter-controls">
            <a
              href="#"
              onClick={() => handleButtonClick("male")}
              className={`filter-button male ${genderFilter === "male" ? "active" : ""}`}
            >
              👨 Мужские
            </a>
            <a
              href="#"
              onClick={() => handleButtonClick("female")}
              className={`filter-button female ${genderFilter === "female" ? "active" : ""}`}
            >
              👩 Женские
            </a>
            <a
              href="#"
              onClick={() => handleButtonClick("kids")}
              className={`filter-button kids ${genderFilter === "kids" ? "active" : ""}`}
            >
              👶 Детские
            </a>
          </div>
          <button
            className={`settings-button ${isPopoverOpen ? 'active' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              console.log('Settings button clicked');
              console.log('Current button class:', e.currentTarget.className);
              console.log('Current isPopoverOpen state:', isPopoverOpen);
              setIsPopoverOpen(!isPopoverOpen);
            }}
            onMouseDown={(e) => {
              console.log('Button mousedown event');
              e.preventDefault(); // Предотвратить потерю фокуса
            }}
            style={{ position: 'relative', zIndex: 1001 }}
          >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.8745 3.5H17.6903L17.8541 4.2992L18.4744 7.32548C19.2615 7.62662 19.9912 8.03914 20.643 8.54341L23.6876 7.54063L24.4495 7.28971L24.8598 7.97895L26.3593 10.4981L26.7914 11.224L26.1478 11.7714L23.7571 13.8045C23.8155 14.1952 23.8457 14.5944 23.8457 15C23.8457 15.4056 23.8155 15.8048 23.7571 16.1956L26.1478 18.2287L26.7914 18.776L26.3593 19.502L24.8598 22.0211L24.4495 22.7103L23.6877 22.4594L20.6429 21.4566C19.9911 21.9608 19.2614 22.3733 18.4744 22.6744L17.8541 25.7008L17.6903 26.5H16.8745H13.8755H13.0596L12.8958 25.7008L12.275 22.6721C11.4897 22.3709 10.7615 21.9588 10.111 21.4553L7.06236 22.4594L6.30051 22.7103L5.89024 22.0211L4.39073 19.502L3.9586 18.776L4.60218 18.2287L6.99777 16.1914C6.9398 15.802 6.90985 15.4041 6.90985 15C6.90985 14.5958 6.9398 14.198 6.99775 13.8086L4.60216 11.7714L3.95859 11.224L4.39071 10.4981L5.89023 7.97895L6.3005 7.28972L7.06234 7.54063L10.1109 8.54469C10.7614 8.04113 11.4897 7.62898 12.2751 7.32779L12.8958 4.2992L13.0596 3.5H13.8755H16.8745Z" stroke="#FEFEFE" stroke-width="2" stroke-linecap="round"/>
              <path d="M15.375 18C13.511 18 12 16.489 12 14.625C12 12.761 13.511 11.25 15.375 11.25C17.239 11.25 18.75 12.761 18.75 14.625C18.75 16.489 17.239 18 15.375 18Z" fill="#FEFEFE"/>
            </svg>
          </button>
          <Popover
            isOpen={isPopoverOpen}
            onClose={() => setIsPopoverOpen(false)}
            displayBalance={displayBalance}
            onSettingsClick={(e) => {
              e.stopPropagation();
              setIsSettingsModalOpen(true);
              setIsPopoverOpen(false);
            }}
            onLogout={(e) => {
              e.stopPropagation();
              handleLogout();
            }}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
          />
          
          <SettingsModal 
            isOpen={isSettingsModalOpen}
            onClose={() => setIsSettingsModalOpen(false)}
            currentUser={currentUser}
            styleGroups={styleGroups}
          />
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
