import React, { useRef, useEffect } from "react";
import "./StyleGrid.scss";
import PropTypes from "prop-types";
import CircleButton from "../CircleButton/CircleButton";
import { useUser } from '../../context/UserContext';

const StyleGrid = ({
  styleGroups,
  genderFilter,
  selectedStyle,
  selectStyle,
  scrollToGroup,
  onNextClick,
  setCurrentVisibleGroup,
  group,
  onStyleSelect,
  isVisible
}) => {
  const containerRef = useRef(null);
  const observerRef = useRef(null);
  const { stylePreferences } = useUser();

  const handleCameraClick = (e, style) => {
    e.stopPropagation();
    if (style && style.prompt) {
      onNextClick();
    } else {
      alert("Выберите стиль перед переходом!");
    }
  };

  useEffect(() => {
    if (scrollToGroup && containerRef.current) {
      const groupElement = containerRef.current.querySelector(
        `[data-group="${scrollToGroup}"]`,
      );
      if (groupElement) {
        groupElement.scrollIntoView({ behavior: "smooth", inline: "start" });
      }
    }
  }, [scrollToGroup]);

  const allStyles = styleGroups.flatMap((group) =>
    group.styles.map((style) => ({ ...style, groupName: group.groupName })),
  );

  const filteredStyles = allStyles.filter(
    (style) => 
      (genderFilter === "all" || style.gender.includes(genderFilter)) &&
      (!stylePreferences || stylePreferences[style.groupName] !== false)
  );

  // Setup intersection observer
  useEffect(() => {
    const setupObserver = () => {
      if (!containerRef.current) return;

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const groupName = entry.target.getAttribute('data-group');
            if (groupName) {
              setCurrentVisibleGroup(groupName);
            }
          }
        });
      }, options);

      const elements = containerRef.current.querySelectorAll('[data-group]');
      elements.forEach(element => observer.observe(element));
      observerRef.current = observer;

      return observer;
    };

    const observer = setupObserver();

    return () => {
      if (observer) {
        observer.disconnect();
      }
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [setCurrentVisibleGroup, filteredStyles]);

  if (!isVisible || filteredStyles.length === 0) {
    return null;
  }

  return (
    <div className="style-grid" ref={containerRef} id={group.groupName}>
      <div className="image-container scroll-snap-container">
        {filteredStyles.map((style, index) => (
          <div
            key={`${style.groupName}-${index}`}
            className={`image-card scroll-snap-item ${selectedStyle && selectedStyle.name === style.name ? "selected" : ""}`}
            onClick={() => selectStyle(style, style.groupName)}
            data-group={style.groupName}
          >
            <div
              className="image-wrapper"
              style={{ backgroundImage: `url(${style.styleImage})` }}
            >
              {style.styleImage.toLowerCase().includes('turbo') && (
                <div className="turbo-icon">⚡️</div>
              )}
              {selectedStyle && selectedStyle.name === style.name && (
                <div 
                  className="glow-effect"
                  style={{ backgroundImage: `url(${style.styleImage})` }}
                ></div>
              )}
            </div>
            {selectedStyle && selectedStyle.name === style.name && (
              <CircleButton
                className="camera-button"
                onClick={(e) => handleCameraClick(e, style)}
                icon="camera"
                color="primary"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

StyleGrid.propTypes = {
  styleGroups: PropTypes.array.isRequired,
  genderFilter: PropTypes.string.isRequired,
  selectedStyle: PropTypes.object,
  selectStyle: PropTypes.func.isRequired,
  scrollToGroup: PropTypes.string,
  onNextClick: PropTypes.func.isRequired,
  setCurrentVisibleGroup: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  onStyleSelect: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired
};

export default StyleGrid;
