import React, { useState, useEffect } from 'react';
import StyleToggle from '../StyleToggle';
import './StylesSettings.scss';

const StylesSettings = ({ stylePreferences, styleGroups, handleStyleToggle }) => {
  const [allEnabled, setAllEnabled] = useState(true);

  // Обновляем состояние allEnabled на основе stylePreferences
  useEffect(() => {
    if (stylePreferences && styleGroups) {
      const allGroupsEnabled = styleGroups.every(
        (group) => stylePreferences[group.groupName] !== false
      );
      setAllEnabled(allGroupsEnabled);
    }
  }, [stylePreferences, styleGroups]);

  const handleToggleAll = () => {
    const newState = !allEnabled;
    setAllEnabled(newState);
    styleGroups.forEach((group) => {
      handleStyleToggle(group.groupName, newState);
    });
  };

  const menuItems = [
    { id: 'general', title: 'Баланс' },
    { id: 'styles', title: 'Стили' },
  ];

  if (!styleGroups || styleGroups.length === 0) {
    return <div className="styles-settings">
      <div className="header-row">
        <h3>Настройки стилей</h3>
        <div className="toggle-all-buttons">
          <button onClick={handleToggleAll}>
            {allEnabled ? 'Выключить все' : 'Включить все'}
          </button>
        </div>
      </div>
      <div className="settings-content-container">
        <p>Стили не найдены</p>
      </div>
    </div>;
  }

  return (
    <div className="styles-settings">
      <div className="header-row">
        <h3>Настройки стилей</h3>
        <div className="toggle-all-buttons">
          <button onClick={handleToggleAll}>
            {allEnabled ? 'Выключить все' : 'Включить все'}
          </button>
        </div>
      </div>
      <div className="settings-content-container">
        <div className="style-groups">
          {styleGroups.map((group) => {
            // Определяем состояние включения из stylePreferences
            const isEnabled = stylePreferences ? stylePreferences[group.groupName] !== false : true;
            
            return (
              <StyleToggle
                key={group.groupName}
                groupName={group.groupName}
                isEnabled={isEnabled}
                onToggle={handleStyleToggle}
                styles={group.styles}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StylesSettings; 