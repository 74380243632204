import React from 'react';
import './CircleButton.scss';

// Импортируем все иконки с правильными путями
import doneIcon from '../../assets/icons/done.svg';
import imageIcon from '../../assets/icons/image.svg';
import printIcon from '../../assets/icons/print.svg';
import qrIcon from '../../assets/icons/qr.svg';
import refreshIcon from '../../assets/icons/refresh.svg';
import cameraIcon from '../../assets/icons/camera.svg';

// Объект с маппингом имен иконок на их импорты
const ICONS = {
  done: doneIcon,
  image: imageIcon,
  print: printIcon,
  qr: qrIcon,
  refresh: refreshIcon,
  camera: cameraIcon,
  // ... другие иконки
};

const CircleButton = ({ 
  onClick, 
  disabled, 
  className, 
  children,
  type = 'button',
  icon,
  color = 'default'
}) => {
  return (
    <button
      type={type}
      className={`circle-button ${className || ''} circle-button--${color}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && (
        <img 
          src={ICONS[icon]} 
          alt="" 
          className={`circle-button__icon circle-button__icon--${icon}`} 
        />
      )}
      {children}
    </button>
  );
};

export default CircleButton; 