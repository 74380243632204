import React from "react";
import "./StyleGroupPanel.scss";
import { useUser } from '../../context/UserContext';

const StyleGroupPanel = ({
  styleGroups,
  currentVisibleGroup,
  genderFilter,
  scrollToGroup,
  CurrentTimeAndFrameCount,
}) => {
  const { stylePreferences } = useUser();

  return (
    <div className="style-group-panel">
      <div className="group-items">
        {styleGroups.map((group, index) => {
          // Skip groups that are disabled in preferences
          if (stylePreferences && stylePreferences[group.groupName] === false) {
            return null;
          }

          const visibleStyles = group.styles.filter(
            (style) =>
              genderFilter === "all" || style.gender.includes(genderFilter),
          );
          if (visibleStyles.length === 0) return null;
          return (
            <div
              key={index}
              className={`group-item ${
                currentVisibleGroup === group.groupName ? "active" : ""
              }`}
              onClick={() => scrollToGroup(group.groupName)}
            >
              <div className="group-item-inner">
                <img
                  src={group.categoryImage || visibleStyles[0].styleImage}
                  alt={group.groupName}
                  className="category-image"
                />
                <h5>{group.groupName}</h5>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StyleGroupPanel;
