import React from 'react';
import './ContentContainer.scss';

const ContentContainer = ({ children, className = '' }) => {
  return (
    <div className={`settings-content-container ${className}`}>
      {children}
    </div>
  );
};

export default ContentContainer; 