import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  sendImageToServer,
  resizeAndConvertToWebP,
  pollGenerationProgress
} from "../../utils/ImageUploader";
import { useStyle } from "../../utils/StyleContext";
import "./ResultsScreen.scss";
import axios from "axios";
import { usePageTransition, PageTransition } from '../../components/PageTransition';
import QRCode from 'qrcode.react';
import { useUser } from '../../context/UserContext';
import Notification from '../../components/Notification';
import { generationMessages, instructionMessages } from '../../constants/generationMessages';
import CircleButton from "../../components/CircleButton/CircleButton";
import wandVideo from '../../assets/wand.mp4';
import doneIcon from '../../assets/done.svg';
// const apiServer = process.env.REACT_APP_API_SERVER;
const apiServer = process.env.REACT_APP_API_SERVER;
const apiToken = "ksdjhfgkljadhbsfijlgnaipudshfrgiopm";

const ResultsScreen = () => {
  const { username, updateBalance } = useUser();
  const location = useLocation();
  const { selectedStyle } = useStyle();
  const [images, setImages] = useState([]);
  const capturedImage = location.state?.capturedImage;
  const validImages = Array.isArray(images) ? images : [];
  const [styleDetails, setStyleDetails] = useState(null);
  const intervalId = useRef(null);
  const [promptId, setPromptId] = useState(null);
  const [isPrintButtonDisabled, setIsPrintButtonDisabled] = useState(true);
  const navigateWithTransition = usePageTransition();
  const [progress, setProgress] = useState(0);
  const [showQR, setShowQR] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [currentMessage, setCurrentMessage] = useState(generationMessages[0]);
  const [isFading, setIsFading] = useState(false);
  const [currentInstruction, setCurrentInstruction] = useState("Фото обрабатывается, нужно немного подождать");
  const progressInterval = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [hasRequestedGeneration, setHasRequestedGeneration] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const [result, setResult] = useState(null);
  const [isLooping, setIsLooping] = useState(true);
  const [error, setError] = useState(null);
  const [showProgressBar, setShowProgressBar] = useState(true);

  useEffect(() => {
    if (!location.state?.capturedImage || !selectedStyle) {
      navigateWithTransition('/');
    }
  }, [location.state, selectedStyle, navigateWithTransition]);

  const addNotification = useCallback((message, type = 'success') => {
    setNotifications(prev => [...prev, { message, type }]);
    
    setTimeout(() => {
      setNotifications(prev => prev.slice(1));
    }, 5000);
  }, []);

  const generateImage = useCallback(async () => {
    const startPolling = async (promptId, clientId) => {
      try {
        console.log(`Starting polling with clientId: ${clientId}`);
        addNotification('Фото успешно отправлено на обработку', 'success');
        const generatedImages = await pollGenerationProgress(clientId, apiToken, (serverProgress) => {
          setProgress(current => Math.max(current, serverProgress));
        });
        
        if (generatedImages && generatedImages.length > 0) {
          setImages(generatedImages);
          setShowProgressBar(false);
          setIsPrintButtonDisabled(false);
          setCurrentMessage("Ваше фото готово");
          setCurrentInstruction(instructionMessages[Math.floor(Math.random() * instructionMessages.length)]);
          if (progressInterval.current) {
            clearInterval(progressInterval.current);
          }
          setShowVideo(false);
        } else {
          addNotification('Ошибка при генерации фото. Пожалуйста, попробуйте еще раз.', 'error');
          setShowProgressBar(false);
          setShowVideo(false);
          if (progressInterval.current) {
            clearInterval(progressInterval.current);
          }
          setTimeout(() => {
            navigateWithTransition('/');
          }, 3000);
        }
      } catch (error) {
        console.error("Error in polling sequence:", error);
        addNotification(error.message || 'Ошибка при генерации фото. Пожалуйста, попробуйте еще раз.', 'error');
        setError("An error occurred while generating the image");
        setShowProgressBar(false);
        setShowVideo(false);
        if (progressInterval.current) {
          clearInterval(progressInterval.current);
        }
        setTimeout(() => {
          navigateWithTransition('/');
        }, 3000);
      }
    };

    try {
      const currentBalance = await updateBalance();
      if (currentBalance <= 0) {
        addNotification('Генерации закончились. Пожалуйста, пополните баланс.', 'error');
        setTimeout(() => {
          navigateWithTransition('/');
        }, 3000);
        return;
      }

      setProgress(0);
      
      const resizedImage = await resizeAndConvertToWebP(capturedImage, 1024);
      let serverIndex = 0;
      let maxRetries = 3; // Максимальное количество попыток
      let success = false;

      while (!success && serverIndex < maxRetries) {
        try {
          const response = await sendImageToServer(
            resizedImage,
            styleDetails,
            apiToken,
            username || 'anonymous',
            serverIndex
          );

          if (response && response.promptId && response.clientId) {
            setPromptId(response.promptId);
            console.log(`Starting polling with promptId: ${response.promptId} and clientId: ${response.clientId}`);
            startPolling(response.promptId, response.clientId);
            updateBalance();
            success = true;
          } else {
            serverIndex++;
            if (serverIndex >= maxRetries) {
              addNotification('Все сервера недоступны. Пожалуйста, попробуйте позже.', 'error');
              throw new Error("All servers are unavailable");
            }
          }
        } catch (error) {
          if (error.message.includes("connection failed") || error.message.includes("NetworkError")) {
            serverIndex++;
            if (serverIndex >= maxRetries) {
              addNotification('Все сервера недоступны. Пожалуйста, попробуйте позже.', 'error');
              throw new Error("All servers are unavailable");
            }
            continue;
          }
          throw error;
        }
      }
    } catch (error) {
      console.error("Error in generateImage:", error);
      if (
        error.response?.status === 402 || 
        error.response?.data?.message?.includes('Недостаточно средств') ||
        error.response?.data?.includes('Недостаточно средств') ||
        error.message?.includes('Недостаточно средств')
      ) {
        addNotification("Недостаточно средств на балансе. Пожалуйста, пополните баланс.", 'error');
        setTimeout(() => {
          navigateWithTransition('/');
        }, 3000);
      } else if (error.message === "All servers are unavailable") {
        setTimeout(() => {
          navigateWithTransition('/');
        }, 3000);
      } else {
        console.error("Detailed error:", {
          status: error.response?.status,
          data: error.response?.data,
          message: error.message
        });
        addNotification("Ошибка при отправке фото. Нет связи с сервером.", 'error');
      }
      setShowProgressBar(false);
    }
  }, [
    capturedImage, 
    styleDetails, 
    username, 
    updateBalance, 
    navigateWithTransition, 
    addNotification,
    setProgress, 
    setImages, 
    setIsPrintButtonDisabled, 
    setCurrentMessage, 
    setCurrentInstruction, 
    setShowVideo, 
    progressInterval
  ]);

  useEffect(() => {
    if (selectedStyle) {
      setStyleDetails({
        prompt: selectedStyle.prompt,
        image: selectedStyle.styleImage || selectedStyle.image,
      });
    }
  }, [selectedStyle]);

  useEffect(() => {
    if (capturedImage && styleDetails && !hasRequestedGeneration) {
      setHasRequestedGeneration(true);
      updateBalance().then(() => {
        generateImage();
      });
    }
  }, [capturedImage, styleDetails, hasRequestedGeneration, generateImage, updateBalance]);

  const handlePrint = async () => {
    if (!promptId) return;

    try {
      const currentBalance = await updateBalance();
      console.log('Current balance after update:', currentBalance);
      
      if (currentBalance <= 0) {
        console.log('Balance check failed:', currentBalance);
        addNotification('Недостаточно средств на балансе. Пожалуйста, пополните баланс.', 'error');
        return;
      }

      setIsPrintButtonDisabled(true);
      const token = localStorage.getItem('token');
      
      if (!token) {
        console.error("No token found in localStorage");
        setError("Please log in again");
        navigateWithTransition('/login');
        return;
      }

      const response = await axios.post(
        `${apiServer}/print/${promptId}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': apiToken,
            'Authorization': `Bearer ${token}`
          },
          withCredentials: true
        }
      );
      
      console.log("Print request successful:", response.data);
      const currentCount = parseInt(localStorage.getItem("frameCount") || "0");
      localStorage.setItem("frameCount", (currentCount + 1).toString());
      
      addNotification('Фото отправлено на печать', 'success');
      
      setTimeout(() => {
        navigateWithTransition('/');
      }, 2000);
      
    } catch (error) {
      console.error("Print error:", error);
      console.error("Error response:", error.response?.data);
      console.error("Error status:", error.response?.status);
      
      if (error.response?.status === 401) {
        console.error("Token validation failed:", error.response?.data);
        setError("Session expired. Please log in again.");
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        navigateWithTransition('/login');
      } else {
        setError("Error printing: " + (error.response?.data?.detail || error.message));
      }
      setIsPrintButtonDisabled(false);
    }
  };

  const handleRetry = () => {
    setImages([]);
    setPromptId(null);
    setError(null);
    setIsPrintButtonDisabled(true);
    setShowVideo(true);
    setIsLooping(true);
    setCurrentMessage(generationMessages[0]);
    setCurrentInstruction("Делаем еще один вариант, нужно немного подождать");
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    generateImage();
  };

  const handleClose = () => {
    navigateWithTransition('/');
  };

  const getImageUrl = async () => {
    if (showQR) {
      setShowQR(false);
      return;
    }

    if (imageUrl) {
      setShowQR(true);
      return;
    }

    try {
      const response = await axios.get(
        `${apiServer}/image-url/${promptId}`,
        {
          headers: { 
            "X-API-Key": apiToken
          }
        }
      );

      setImageUrl(response.data.url);
      setShowQR(true);
    } catch (error) {
      console.error("Error getting image URL:", error);
      if (error.response?.status === 404) {
        setError("Image not found. Please try generating the image again.");
      } else {
        setError("Failed to generate QR code");
      }
    }
  };

  const toggleQRCode = () => {
    if (!imageUrl) {
      getImageUrl();
    } else {
      setShowQR(!showQR);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showQR && !event.target.closest('.qr-modal') && !event.target.closest('.qr-button')) {
        setShowQR(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showQR]);

  useEffect(() => {
    const messageInterval = setInterval(() => {
      if (validImages.length === 0) {
        setIsFading(true);
        
        setTimeout(() => {
          const randomIndex = Math.floor(Math.random() * generationMessages.length);
          setCurrentMessage(generationMessages[randomIndex]);
          setIsFading(false);
        }, 500);
      }
    }, 5000);
    
    return () => clearInterval(messageInterval);
  }, [validImages.length]);

  useEffect(() => {
    if (result?.url) {
      setIsLooping(false);
      setShowVideo(false);
    }
  }, [result]);

  return (
    <div className="camera-screen-container">
      <PageTransition />
      
      
      
      <div className="camera-section">
      
      
        <div className="parent-container">
          <Notification notifications={notifications} />
          {validImages.length > 0 && (
            <div className="button-controls">
              <CircleButton
                className="refresh-button"
                onClick={handleRetry}
                disabled={validImages.length === 0}
                icon="refresh"
              />

              <CircleButton
                className="qr-button"
                onClick={toggleQRCode}
                disabled={validImages.length === 0}
                icon="qr"
              />

              {showQR && imageUrl && (
                <div className="qr-modal">
                  <div className="qr-content">
                    <QRCode value={imageUrl} size={256} level="H" />
                    <p>Отсканируйте QR-код, чтобы получить фотографию</p>
                  </div>
                </div>
              )}

              <CircleButton
                className="send-button"
                onClick={handlePrint}
                disabled={validImages.length === 0 || isPrintButtonDisabled}
                icon="print"
              />
            </div>
          )}
          
          {validImages.length > 0 ? (
            <div 
              className="parent-container"
              style={{
                '--bg-image': `url(${validImages[0]})`
              }}
            >
              <img src={validImages[0]} alt="Result" className="captured-image" />
            </div>
          ) : (
            <div className="parent-container">
              <Notification notifications={notifications} />
              <div className="loading-container">
                <div className="background-layer"></div>
                <div 
                  className="progress-layer" 
                  style={{ width: `${progress}%` }}
                ></div>
                
              </div>
            </div>
          )}
          
          
        </div>
      </div>

      <div className="second-section">
        <div className="content-wrapper">
          <button 
            type="button"
            className="close-button" 
            onClick={handleClose}
            style={{ cursor: 'pointer' }}
          >
            <span className="close-icon"></span>
          </button>

          <div className="content-blocks">
            <div className="content-block active">
              {showVideo ? (
                <video 
                  autoPlay 
                  loop={isLooping}
                  muted 
                  playsInline 
                  className="wand-animation"
                >
                  <source src={wandVideo} type="video/mp4" />
                </video>
              ) : (
                <img 
                  src={doneIcon} 
                  alt="Done" 
                  className="wand-animation"
                  style={{ width: '150px', height: '150px' }}
                />
              )}
              <h1 className={`generation-message ${isFading ? 'fade-out' : ''}`}>
                {currentMessage}
              </h1>
              <p>{currentInstruction}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsScreen;
