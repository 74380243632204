import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import './LoginScreen.scss';
import { useUser } from '../../context/UserContext';

const API_BASE_URL = process.env.REACT_APP_API_SERVER;

// Компонент для отображения системных сообщений
const SystemMessage = ({ type, message, onClose }) => {
  if (!message) return null;

  return (
    <div className={`system-message ${type}-message`}>
      <div className="message-content">
        {message}
      </div>
      {onClose && (
        <button className="close-button" onClick={onClose}>
          ×
        </button>
      )}
    </div>
  );
};

// Схема валидации с использованием Yup
const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required('Логин обязателен')
    .min(3, 'Логин должен содержать минимум 3 символа'),
  password: Yup.string()
    .required('Пароль обязателен')
    .min(6, 'Пароль должен содержать минимум 6 символов')
});

const LoginScreen = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [systemMessage, setSystemMessage] = useState({ type: '', message: '' });
  const [touchedFields, setTouchedFields] = useState({
    username: false,
    password: false
  });
  const navigate = useNavigate();
  const { setUsername: setGlobalUsername } = useUser();

  const clearSystemMessage = () => {
    setSystemMessage({ type: '', message: '' });
  };

  const validateField = async (name, value) => {
    try {
      // Создаем временный объект с текущими значениями формы и обновленным полем
      const fieldSchema = Yup.reach(validationSchema, name);
      await fieldSchema.validate(value);
      
      // Если валидация прошла успешно, удаляем ошибку для этого поля
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    } catch (error) {
      // Если валидация не прошла, устанавливаем сообщение об ошибке
      setErrors(prev => ({
        ...prev,
        [name]: error.message
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Отмечаем поле как "тронутое"
    if (!touchedFields[name]) {
      setTouchedFields(prev => ({
        ...prev,
        [name]: true
      }));
    }
    
    // Валидация поля при изменении
    validateField(name, value);
  };

  // Функция для проверки наличия ошибок в форме
  const hasErrors = () => {
    // Проверяем только ошибки в полях, которые пользователь уже заполнял
    return Object.keys(errors).some(field => 
      touchedFields[field] && errors[field] !== undefined
    );
  };

  // Функция для проверки заполненности всех полей
  const isFormFilled = () => {
    return Object.values(formData).every(value => value.trim() !== '');
  };

  // Функция для определения, должна ли кнопка быть отключена
  const isSubmitDisabled = () => {
    return hasErrors() || !isFormFilled();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearSystemMessage();

    // Отмечаем все поля как "тронутые" при отправке формы
    const allTouched = Object.keys(touchedFields).reduce((acc, field) => {
      acc[field] = true;
      return acc;
    }, {});
    setTouchedFields(allTouched);

    try {
      // Валидация всей формы перед отправкой
      await validationSchema.validate(formData, { abortEarly: false });

      const params = new URLSearchParams();
      params.append('username', formData.username);
      params.append('password', formData.password);

      const response = await fetch(`${API_BASE_URL}/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Invalid credentials');
      }

      const data = await response.json();
      
      if (!data.access_token) {
        throw new Error('No token received from server');
      }
      
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('username', formData.username);
      setGlobalUsername(formData.username);
      
      navigate('/');
    } catch (err) {
      console.error("Login error:", err);
      
      if (err.name === 'ValidationError') {
        // Обработка ошибок валидации Yup
        const validationErrors = {};
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
        
        // Показываем общее сообщение об ошибке
        setSystemMessage({ 
          type: 'error', 
          message: 'Пожалуйста, исправьте ошибки в форме' 
        });
      } else {
        // Обработка других ошибок
        setSystemMessage({ 
          type: 'error', 
          message: err.message || 'Неверный логин или пароль' 
        });
      }
    }
  };

  return (
    <div className="login-screen">
      <div className="login-container">
        <div className="form-side">
          <SystemMessage 
            type={systemMessage.type} 
            message={systemMessage.message} 
            onClose={clearSystemMessage} 
          />
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="username"
                placeholder="Логин"
                value={formData.username}
                onChange={handleChange}
                required
                className={touchedFields.username && errors.username ? 'error' : ''}
              />
              {touchedFields.username && errors.username && <div className="input-error-message">{errors.username}</div>}
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password"
                placeholder="Пароль"
                value={formData.password}
                onChange={handleChange}
                required
                className={touchedFields.password && errors.password ? 'error' : ''}
              />
              {touchedFields.password && errors.password && <div className="input-error-message">{errors.password}</div>}
            </div>
            <button type="submit" disabled={isSubmitDisabled()}>Войти в нейробудку</button>
            <div className="forgot-password-link">
              <a href="/register">Зарегистрироваться</a>
              <a href="/forgot-password">Восстановить доступ</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen; 