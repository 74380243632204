import React, { useState, useEffect } from 'react';
import { useUser } from '../../../context/UserContext';
import ContentContainer from './ContentContainer';
import './GeneralSettings.scss';
import QRCode from 'qrcode.react';
import { ReactComponent as ImageIcon } from "../../../assets/icons/image.svg";

const GeneralSettings = ({ isLoading, handleAddBalance }) => {
  const { balance, username } = useUser();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [checkingInterval, setCheckingInterval] = useState(null);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [showQRModal, setShowQRModal] = useState(false);
  const [currentPackage, setCurrentPackage] = useState(null);

  // Добавляем определение displayBalance
  const displayBalance = balance || 0;

  const generationPackages = [
    { amount: 30, price: 1200, pricePerUnit: 40 },
    { amount: 60, price: 2000, pricePerUnit: 33 },
    { amount: 150, price: 4500, pricePerUnit: 30 },
    { amount: 300, price: 7500, pricePerUnit: 25 },
    { amount: 650, price: 14950, pricePerUnit: 23 },
    { amount: 1000, price: 20000, pricePerUnit: 20 }
  ];

  // Функция для проверки статуса платежа
  const checkPaymentStatus = async (paymentId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER}/check-payment/${paymentId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      
      if (data.status === 'succeeded') {
        clearInterval(checkingInterval);
        setCheckingInterval(null);
        setQrCodeUrl(null);
        setPaymentId(null);
        setShowQRModal(false);
        if (handleAddBalance) {
          await handleAddBalance();
        }
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
    }
  };

  const handlePayment = async (amount, generations) => {
    try {
      setPaymentLoading(true);
      // Сохраняем информацию о текущем пакете
      setCurrentPackage({ amount: generations, price: amount });
      
      const response = await fetch(`${process.env.REACT_APP_API_SERVER}/create-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          amount: amount,
          generations: generations,
          return_url: window.location.origin + window.location.pathname
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Payment response:', data);

      if (data.confirmation_url) {
        setPaymentUrl(data.confirmation_url);
        setPaymentId(data.payment_id);
        setShowQRModal(true);
        
        // Начинаем проверять статус платежа каждые 3 секунды
        const interval = setInterval(() => checkPaymentStatus(data.payment_id), 3000);
        setCheckingInterval(interval);
      } else {
        throw new Error('No confirmation URL in response');
      }
    } catch (error) {
      console.error('Payment error:', error);
      alert(`Ошибка при создании платежа: ${error.message}`);
    } finally {
      setPaymentLoading(false);
    }
  };

  // Очищаем интервал при размонтировании компонента
  React.useEffect(() => {
    return () => {
      if (checkingInterval) {
        clearInterval(checkingInterval);
      }
    };
  }, [checkingInterval]);

  // Добавляем обработчик клика вне модального окна
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showQRModal && !event.target.closest('.qr-modal-content')) {
        setShowQRModal(false);
        if (checkingInterval) {
          clearInterval(checkingInterval);
          setCheckingInterval(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showQRModal, checkingInterval]);

  return (
    <div className="account-settings">
      <ContentContainer>
        <div className="header-container">
          <div className="title-section">
            <h4>Пополнение баланса</h4>
            <p className="description-text">
              Выберите подходящий пакет генераций.
            </p>
          </div>
          <div className="balance-info">
            <ImageIcon className="image-icon" />
            <span className="available-generations-label">
              Доступно: {balance !== null ? balance : 'Загрузка...'}
            </span>
          </div>
        </div>
        
        <div className="packages-grid">
          {generationPackages.map((pkg, index) => (
            <div key={index} className="package-card">
              {index === generationPackages.length - 1 && (
                <span className="best-deal-badge">выгодно</span>
              )}
              <div className="package-amount">
                <span className="amount-badge">{pkg.amount} шт</span>
              </div>
              <div className="package-price-per-unit">{pkg.pricePerUnit} ₽</div>
              <div className="package-subtitle">за генерацию</div>
              <button 
                className="package-button"
                onClick={() => handlePayment(pkg.price, pkg.amount)}
                disabled={paymentLoading || isLoading}
              >
                {paymentLoading ? 'Загрузка...' : `${pkg.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
              </button>
            </div>
          ))}
        </div>
      </ContentContainer>

      {showQRModal && paymentUrl && (
        <div className="payment-qr-modal">
          <div className="qr-modal-content">
            <button 
              className="close-button" 
              onClick={() => {
                setShowQRModal(false);
                if (checkingInterval) {
                  clearInterval(checkingInterval);
                  setCheckingInterval(null);
                }
              }}
            >
              ×
            </button>
            <div className="qr-code-container">
              <QRCode value={paymentUrl} size={256} level="H" />
            </div>
            <p>
              Отсканируйте QR-код для оплаты {currentPackage?.price} ₽ за пакет из {currentPackage?.amount} генераций
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralSettings; 