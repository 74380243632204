import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./CameraScreen.scss";
import useCamera from "../../utils/useCamera";
import FrameComponent from "../../components/FrameComponent";
import { usePageTransition } from '../../components/PageTransition';
import camInfoImage from '../../assets/images/cam-info.png';
import { useUser } from '../../context/UserContext';
import CircleButton from "../../components/CircleButton/CircleButton";
import { useStyle } from "../../utils/StyleContext";

const CameraScreen = () => {
  const { selectedStyle } = useStyle();
  const { username } = useUser();
  const [image, setImage] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const backgroundVideoRef = useRef(null);
  const canvasRef = useRef(null);
  const { captureImage, restartVideoStream } = useCamera(
    videoRef,
    canvasRef,
    setImage,
  );
  const [showWhiteScreen, setShowWhiteScreen] = useState(false);
  const [progressDegrees, setProgressDegrees] = useState(360);
  const navigateWithTransition = usePageTransition();
  const [isSparkleActive, setIsSparkleActive] = useState(false);

  useEffect(() => {
    if (!selectedStyle) {
      navigate('/');
    }
  }, [selectedStyle, navigate]);

  useEffect(() => {
    if (countdown > 0 && countdown <= 3) {
      const blinkInterval = setInterval(() => {
        const videoContainer = document.querySelector(".parent-container");
        if (videoContainer) {
          videoContainer.classList.toggle("blink-border");
        }
      }, 400);

      return () => clearInterval(blinkInterval);
    }
  }, [countdown]);

  useEffect(() => {
    if (countdown > 0) {
      setProgressDegrees(((countdown) / 3) * 100);
    }
  }, [countdown]);

  useEffect(() => {
    if (showWhiteScreen) {
      setIsSparkleActive(true);
      setTimeout(() => {
        setIsSparkleActive(false);
      }, 2500);
    }
  }, [showWhiteScreen]);

  const startCaptureWithCountdown = () => {
    let count = 3;
    setCountdown(count);
    setProgressDegrees(360);
    setShowWhiteScreen(false);

    const timer = setInterval(() => {
      count -= 1;
      setCountdown(count);
      setShowWhiteScreen(count === 0);

      if (count === 0) {
        clearInterval(timer);
        setTimeout(() => {
          captureImage();
          setTimeout(() => {
            setShowWhiteScreen(false);
            setCountdown(null);
          }, 300);
        }, 300);
      }
    }, 1000);
  };

  const handleSendButtonClick = () => {
    if (image) {
      navigate("/results", { 
        state: { 
          capturedImage: image,
          username: username 
        } 
      });
    } else {
      console.error("Image not captured");
    }
  };

  const handleBackClick = () => {
    navigateWithTransition('/');
  };

  const handleDeleteClick = () => {
    setImage(null);
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  return (
    <div className="camera-screen-container">
      <FrameComponent />
      <div className={`white-screen ${showWhiteScreen ? 'visible' : ''} ${isSparkleActive ? 'sparkles' : ''}`}></div>
      
      <div className="camera-section">
        <div className="parent-container">
          
          <video ref={videoRef} className="videoInsert" autoPlay muted></video>
          <svg 
            className="overlay-svg"
            width="315" 
            height="436" 
            viewBox="0 0 315 436" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              d="M313 218C313 337.293 243.38 434 157.5 434C71.6197 434 2 337.293 2 218C2 98.7065 71.6197 2 157.5 2C243.38 2 313 98.7065 313 218Z" 
              stroke="white" 
              strokeWidth="4"               strokeDasharray="10 10"
            />
          </svg>
          {image && (
            <img
              src={image}
              alt="Захваченное зображение"
              className="captured-image"
            />
          )}
          <canvas
            ref={canvasRef}
            className={image ? "canvas-hidden" : ""}
          ></canvas>
          {image ? (
            <div className="button-controls">
              <CircleButton
                className="control-button"
                onClick={handleDeleteClick}
                icon="refresh"
                color="black"
              />
              <CircleButton
                className="control-button"
                onClick={handleSendButtonClick}
                icon="done"
                color="black"
              />
            </div>
          ) : (
            <button
              className={`capture-button ${countdown !== null ? 'countdown-active' : ''}`}
              onClick={startCaptureWithCountdown}
            >
              {countdown !== null ? (
                <div className="countdown-wrapper">
                  <svg className="progress-ring" viewBox="0 0 36 36">
                    <path
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                      fill="none"
                      stroke="rgba(255, 255, 255, 0.3)"
                      strokeWidth="3"
                    />
                    <path
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="3"
                      strokeDasharray={`${progressDegrees}, 100`}
                    />
                  </svg>
                  <div className="countdown-text">{countdown}</div>
                </div>
              ) : (
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20 28C16.6863 28 14 25.3137 14 22C14 18.6863 16.6863 16 20 16C23.3137 16 26 18.6863 26 22C26 25.3137 23.3137 28 20 28Z" fill="white"/>
                  <path d="M2.60931 12.5626L2 13.1522V14V31V31.8284L2.58579 32.4142L4.58579 34.4142L5.17157 35H6L34 35H34.8284L35.4142 34.4142L37.4142 32.4142L38 31.8284V31V14V13.1522L37.3907 12.5626L35.3907 10.6276L34.8092 10.0649H34L28.969 10.0649L25.5696 5.76045L24.969 5H24H20H16H15.031L14.4304 5.76045L11.031 10.0649L6 10.0649H5.19084L4.60931 10.6276L2.60931 12.5626Z" stroke="white" stroke-width="4" stroke-linecap="round"/>
                </svg>
              )}
            </button>
          )}
        </div>
      </div>

      <div className="second-section">
        <div className="content-wrapper">
          <button 
            type="button"
            className="close-button" 
            onClick={handleBackClick}
            style={{ cursor: 'pointer' }}
          >
            <span className="close-icon"></span>
          </button>

          <div className="content-blocks">
            <div className={`content-block ${!image ? 'active' : ''}`}>
              <img src={camInfoImage} alt="Camera instructions" className="cam-info-image" />
              <h1>Сделаем фото?</h1>
              <p>Поместите лицо в овальную область и посмотрите в камеру, затем сделайте удачный снимок.</p>
            </div>

            <div className={`content-block ${image ? 'active' : ''}`}>
              <img src={camInfoImage} alt="Camera instructions" className="cam-info-image" />
              <h1>Отправляем?</h1>
              <p>Если с фото все ок, отправляйте его на обработку. Через несколько секунд вы получите результат.</p>
            </div>
          </div>

          <div className="indicator-dots">
            {[0, 1].map((index) => (
              <div
                key={index}
                className={`dot ${(image ? 1 : 0) === index ? 'active' : ''}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraScreen; 
