import React, { useEffect, useState } from 'react';
import './LoadingProgress.scss';

const LoadingProgress = () => {
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const getPerformanceProgress = () => {
      // If you have navigation timing API available
      if (window.performance && window.performance.timing) {
        const timing = window.performance.timing;
        const loadTime = timing.loadEventStart - timing.navigationStart;
        const maxLoadTime = 3000; // 3 seconds as maximum expected load time
        return Math.min((loadTime / maxLoadTime) * 100, 100);
      }
      return 0;
    };

    const calculateProgress = () => {
      const images = document.images;
      const totalImages = images.length;
      let loadedImages = 0;

      function imageLoaded() {
        loadedImages++;
        const currentProgress = (loadedImages / totalImages) * 100;
        setProgress(currentProgress);

        if (loadedImages === totalImages) {
          setTimeout(() => {
            setIsVisible(false);
          }, 500);
        }
      }

      // Проверяем все изображения
      for (let i = 0; i < totalImages; i++) {
        const img = images[i];
        if (img.complete) {
          imageLoaded();
        } else {
          img.addEventListener('load', imageLoaded);
          img.addEventListener('error', imageLoaded); // Считаем ошибки тоже как загруженные
        }
      }

      // Если нет изображений, скрываем прогресс-бар
      if (totalImages === 0) {
        setProgress(100);
        setTimeout(() => {
          setIsVisible(false);
        }, 500);
      }

      // Добавьте проверку общего прогресса
      const performanceProgress = getPerformanceProgress();
      const totalProgress = (performanceProgress + progress) / 2;
      setProgress(totalProgress);
    };

    calculateProgress();
    
    // Добавьте слушатель для load события
    window.addEventListener('load', () => {
      setProgress(100);
      setTimeout(() => {
        setIsVisible(false);
      }, 500);
    });

    return () => {
      window.removeEventListener('load', calculateProgress);
    };
  }, []);

  if (!isVisible) return null;

  return (
    <div className="loading-progress">
      <div 
        className="loading-progress__bar" 
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

export default LoadingProgress; 