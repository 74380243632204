import React from 'react';
import ReactDOM from 'react-dom';
import './SettingsModal.scss';
import { useUser } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import GeneralSettings from './SettingsModal/GeneralSettings';
import StylesSettings from './SettingsModal/StylesSettings';

const SettingsModal = ({ isOpen, onClose, styleGroups }) => {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = React.useState('general');
  const { stylePreferences, updateBalance, loadStylePreferences, updateStylePreferences } = useUser();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [tempStylePreferences, setTempStylePreferences] = React.useState({});

  const handleAuthError = (response) => {
    if (response?.status === 401) {
      localStorage.clear();
      navigate('/login', { replace: true });
      onClose();
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (isOpen && !isInitialized) {
      const initializeSettings = async () => {
        try {
          const balanceResponse = await updateBalance();
          if (handleAuthError(balanceResponse)) return;

          if (Object.keys(stylePreferences).length === 0) {
            const preferencesResponse = await loadStylePreferences();
            if (handleAuthError(preferencesResponse)) return;
          }
          setIsInitialized(true);
          // Инициализируем временные настройки при открытии
          setTempStylePreferences({ ...stylePreferences });
        } catch (error) {
          console.error('Error initializing settings:', error);
        }
      };
      
      initializeSettings();
    }
  }, [isOpen]);

  const handleAddBalance = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      
      if (!token) {
        handleAuthError({ status: 401 });
        return;
      }

      const balanceResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/users/balance/${localStorage.getItem('username')}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (handleAuthError(balanceResponse)) return;

      if (!balanceResponse.ok) {
        throw new Error(`HTTP error! status: ${balanceResponse.status}`);
      }

      const balanceData = await balanceResponse.json();
      if (updateBalance) {
        await updateBalance(balanceData.balance);
      }

    } catch (error) {
      console.error('Ошибка обновления баланса:', error);
      if (error.message?.includes('401')) {
        handleAuthError({ status: 401 });
        return;
      }
      alert('Не удалось обновить баланс');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStyleToggle = (groupName) => {
    try {
      setTempStylePreferences(prev => ({
        ...prev,
        [groupName]: !prev[groupName]
      }));
    } catch (error) {
      console.error('Error updating style preferences:', error);
      alert('Не удалось обновить настройки стилей');
    }
  };

  const handleClose = async () => {
    try {
      setIsLoading(true);
      
      // Проверяем, были ли изменения
      const hasChanges = JSON.stringify(tempStylePreferences) !== JSON.stringify(stylePreferences);
      
      if (hasChanges) {
        const preferencesResponse = await updateStylePreferences(tempStylePreferences);
        if (handleAuthError(preferencesResponse)) return;

        window.dispatchEvent(new CustomEvent('stylePreferencesUpdated', {
          detail: { preferences: tempStylePreferences }
        }));
      }

      onClose();
    } catch (error) {
      console.error('Error saving preferences:', error);
      alert('Не удалось сохранить настройки');
    } finally {
      setIsLoading(false);
    }
  };

  const menuItems = [
    { id: 'general', title: 'Баланс' },
    { id: 'styles', title: 'Стили' },
  ];

  const renderContent = () => {
    switch(selectedMenuItem) {
      case 'general':
        return <GeneralSettings 
          isLoading={isLoading} 
          handleAddBalance={handleAddBalance} 
        />;
      case 'styles':
        return <StylesSettings 
          stylePreferences={tempStylePreferences}
          styleGroups={styleGroups}
          handleStyleToggle={handleStyleToggle}
        />;
      default:
        return <div>Выберите раздел настроек</div>;
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-body">
          <div className="settings-container">
            <div className="settings-menu">
              {menuItems.map(item => (
                <div
                  key={item.id}
                  className={`menu-item ${selectedMenuItem === item.id ? 'active' : ''}`}
                  onClick={() => setSelectedMenuItem(item.id)}
                >
                  {item.title}
                </div>
              ))}
            </div>
            <div className="settings-content">
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default SettingsModal; 