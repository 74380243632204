import React, { useState, useEffect } from "react";
import { useStyle } from "./StyleContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useUser } from '../context/UserContext';
// const apiServer = process.env.REACT_APP_API_SERVER;

const apiServer = process.env.REACT_APP_API_SERVER || "https://api.neuro-booth.ru";

console.log(apiServer);

const resizeAndConvertToWebP = (base64Image, maxWidth) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Устанавливаем размеры холста
      const scaleFactor = maxWidth / img.width;
      canvas.width = maxWidth;
      canvas.height = img.height * scaleFactor;

      // Рисуем изображение
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Создаем квадратную маску
      ctx.globalCompositeOperation = 'destination-in';
      ctx.beginPath();
      const size = Math.min(canvas.width, canvas.height); // берем меньшую сторону для квадрата
      const x = (canvas.width - size) / 2;  // центрируем по горизонтали
      const y = (canvas.height - size) / 2; // центрируем по вертикали
      ctx.rect(x, y, size, size);
      ctx.fill();

      // Сбрасываем операцию наложения
      ctx.globalCompositeOperation = 'source-over';

      const webpImage = canvas.toDataURL("image/webp", 0.85);
      // Ensure proper base64 padding
      const base64Data = webpImage.split(',')[1];
      const padding = base64Data.length % 4;
      const paddedBase64 = padding ? base64Data + '='.repeat(4 - padding) : base64Data;
      resolve('data:image/webp;base64,' + paddedBase64);
    };
    img.onerror = reject;
    img.src = base64Image;
  });
};
const sendImageToServer = async (base64Image, selectedStyle, apiToken, username, serverIndex = 0) => {
  try {
    if (!base64Image) {
      throw new Error("base64Image is undefined");
    }

    // Strip the data URL prefix if present
    const base64Data = base64Image.includes('base64,') 
      ? base64Image.split('base64,')[1] 
      : base64Image;

    // Construct the correct style image URL
    const styleImageUrl = selectedStyle?.styleImage || selectedStyle?.image;
    let fullStyleImageUrl = styleImageUrl;
    
    // Only construct URL if it's not already a full URL
    if (styleImageUrl && !styleImageUrl.startsWith('http')) {
      fullStyleImageUrl = `${apiServer}/styles/${encodeURIComponent(selectedStyle.category)}/${styleImageUrl}`;
    }
    
    console.log('Style image URL:', fullStyleImageUrl);
    console.log('Style category:', selectedStyle?.category);
    console.log('Style image name:', styleImageUrl);

    const payload = {
      selected_prompt: selectedStyle?.prompt || 'nostyle',
      cleaned_image: base64Data,
      source_image: fullStyleImageUrl,
      save_previews: true,
      username: username || 'anonymous',
      server_index: serverIndex // Добавляем индекс сервера в запрос
    };

    console.log('Sending payload:', payload);

    try {
      const response = await fetch(`${apiServer}/generate-image`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": apiToken,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        if (response.status === 503 || errorText.includes("connection failed")) {
          // Если это ошибка соединения с сервером, пробуем следующий
          console.log(`Server ${serverIndex} failed, trying next server...`);
          return sendImageToServer(base64Image, selectedStyle, apiToken, username, serverIndex + 1);
        }
        throw new Error(`Server error: ${errorText}`);
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      if (error.message.includes("Failed to fetch") || error.message.includes("NetworkError")) {
        // При ошибке сети пробуем следующий сервер
        console.log(`Network error with server ${serverIndex}, trying next server...`);
        return sendImageToServer(base64Image, selectedStyle, apiToken, username, serverIndex + 1);
      }
      throw error;
    }
  } catch (error) {
    console.error("Error in sendImageToServer:", error);
    throw error;
  }
};

// In ImageUploader.js pollGenerationProgress:
const pollGenerationProgress = async (clientId, apiToken, setProgress) => {
  if (!clientId) {
    console.error("No clientId provided for polling progress");
    return;
  }

  return new Promise((resolve, reject) => {
    const eventSource = new EventSource(`${apiServer}/stream-progress/${clientId}?api_key=${apiToken}`);
    let lastProgress = 0;
    let lastMessageType = null;
    let isCompleted = false;
    let lastProgressUpdate = Date.now();
    
    // Add no-progress timeout
    const noProgressTimeout = setTimeout(() => {
      if (!isCompleted) {
        console.error('No progress received for 30 seconds');
        eventSource.close();
        reject(new Error('Нет ответа от сервера. Пожалуйста, попробуйте еще раз.'));
      }
    }, 30000); // 30 seconds timeout
    
    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log('Received SSE data:', data);
        
        // Reset no-progress timeout on any valid progress update
        if (data.progress !== undefined && data.progress !== lastProgress) {
          lastProgressUpdate = Date.now();
          clearTimeout(noProgressTimeout);
          // Set new timeout
          if (!isCompleted) {
            setTimeout(() => {
              if (!isCompleted && Date.now() - lastProgressUpdate > 30000) {
                console.error('Progress stalled for 30 seconds');
                eventSource.close();
                reject(new Error('Генерация остановилась. Пожалуйста, попробуйте еще раз.'));
              }
            }, 30000);
          }
        }
        
        // Проверяем наличие картинки независимо от типа сообщения
        if (data.base64Images && data.base64Images.length > 0) {
          console.log('Generation completed, received images');
          isCompleted = true;
          clearTimeout(noProgressTimeout);
          setProgress(100);
          eventSource.close();
          resolve(data.base64Images);
          return;
        }

        // Проверяем ошибки
        if (data.error) {
          console.error('Server error:', data.error);
          clearTimeout(noProgressTimeout);
          eventSource.close();
          reject(new Error(data.error));
          return;
        }

        // Обрабатываем прогресс
        if (data.progress !== undefined) {
          if (data.progress === -1) {
            console.error('Generation completed too quickly');
            clearTimeout(noProgressTimeout);
            eventSource.close();
            reject(new Error('Что-то пошло не так. Пожалуйста, попробуйте еще раз.'));
            return;
          }
          
          // Игнорируем обновления прогресса после завершения
          if (isCompleted) {
            return;
          }
          
          // Плавное обновление прогресса
          const targetProgress = data.progress;
          if (targetProgress > lastProgress) {
            const step = (targetProgress - lastProgress) / 10;
            let currentProgress = lastProgress;
            
            const smoothUpdate = () => {
              currentProgress = Math.min(targetProgress, currentProgress + step);
              setProgress(currentProgress);
              
              if (currentProgress < targetProgress) {
                requestAnimationFrame(smoothUpdate);
              }
            };
            
            requestAnimationFrame(smoothUpdate);
            lastProgress = targetProgress;
          }
        }

        // Проверяем завершение выполнения
        if (data.type === 'execution_success' || data.type === 'executed') {
          console.log('Execution completed, waiting for image...');
          // Не закрываем соединение, ждем картинку
        }
        
      } catch (error) {
        console.error('Error parsing event data:', error);
        clearTimeout(noProgressTimeout);
        eventSource.close();
        reject(error);
      }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
      if (!isCompleted) {  // Закрываем только если генерация не завершена
        clearTimeout(noProgressTimeout);
        eventSource.close();
        reject(error);
      }
    };

    // Увеличиваем таймаут до 5 минут
    setTimeout(() => {
      if (!isCompleted) {
        console.error('Generation timeout after 5 minutes');
        clearTimeout(noProgressTimeout);
        eventSource.close();
        reject(new Error('Превышено время ожидания генерации. Пожалуйста, попробуйте еще раз.'));
      }
    }, 300000);
  });
};

export { resizeAndConvertToWebP, sendImageToServer, pollGenerationProgress };
