import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/PageTransition.scss';

export const PageTransition = () => {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsActive(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`page-transition ${isActive ? 'active' : ''}`} />
  );
};

export const usePageTransition = () => {
  const navigate = useNavigate();
  
  const navigateWithTransition = (to) => {
    const transitionElement = document.querySelector('.page-transition');
    if (!transitionElement) return navigate(to);

    transitionElement.classList.add('active');
    setTimeout(() => {
      navigate(to);
      setTimeout(() => {
        const newTransitionElement = document.querySelector('.page-transition');
        if (newTransitionElement) {
          newTransitionElement.classList.remove('active');
        }
      }, 500);
    }, 500);
  };

  return navigateWithTransition;
}; 