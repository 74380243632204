import React, { useEffect, useState } from 'react';
import './Notification.scss';

const Notification = ({ notifications }) => {
  const [activeNotifications, setActiveNotifications] = useState([]);

  useEffect(() => {
    if (notifications?.length > 0) {
      // Берем только последнее уведомление из новых
      const latestNotification = notifications[notifications.length - 1];
      
      // Проверяем, нет ли такого же уведомления в активных
      const isDuplicate = activeNotifications.some(
        active => active.message === latestNotification.message
      );

      if (!isDuplicate) {
        const notification = {
          ...latestNotification,
          id: Date.now()
        };

        setActiveNotifications([notification]);

        // Удаляем уведомление через 3 секунды
        setTimeout(() => {
          setActiveNotifications(prev => 
            prev.filter(n => n.id !== notification.id)
          );
        }, 3000);
      }
    }
  }, [notifications]);

  if (activeNotifications.length === 0) return null;

  return (
    <div className="notifications-container">
      {activeNotifications.map((notification) => (
        <div 
          key={notification.id}
          className={`notification ${notification.type} visible`}
        >
          {notification.message}
        </div>
      ))}
    </div>
  );
};

export default Notification; 