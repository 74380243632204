export const generationMessages = [
    "Смешиваем цифровые краски",
    "Добавляем волшебство",
    "Создаём шедевр",
    "Настраиваем фильтры",
    "Вдохновляемся искусством",
    "Творим магию",
    "Подбираем оттенки",
    "Воплощаем видение",
    "Раскрываем потенциал",
    "Добавляем штрихи",
    "Создаём искусство",
    "Рисуем стиль",
    "Преображаем реальность",
    "Воплощаем идеи",
    "Рисуем кистью",
    "Добавляем флёр",
    "Создаём симфонию",
    "Смешиваем фантазию",
    "Придаём характер",
    "Раскрываем красоту",
    "Создаём эффект",
    "Добавляем искру",
    "Воплощаем замысел",
    "Создаём реальность",
    "Преображаем пиксели",
    "Добавляем магию",
    "Создаём атмосферу",
    "Воплощаем мечты",
    "Рисуем красками",
    "Создаём настроение",
    "Добавляем шарм",
    "Творим искусство",
    "Создаём поэзию",
    "Раскрываем тайну",
    "Добавляем почерк",
    "Создаём образ",
    "Воплощаем идеал",
    "Оживляем фото",
    "Создаём момент",
    "Добавляем видение",
    "Преображаем мир",
    "Создаём чудо",
    "Воплощаем энергию",
    "Добавляем стиль",
    "Создаём волшебство",
    "Раскрываем талант",
    "Творим историю",
    "Создаём красоту",
    "Добавляем душу",
    "Воплощаем мечту"
];

export const instructionMessages = [
    "Нажмите на кнопку справа, чтобы распечатать фотографию, или на ту что посередине, чтобы показать QR-код.",
    "Хотите сохранить момент? Распечатайте фото справа или получите QR-код в центре.",
    "Ваш шедевр готов! Печать - справа, QR-код - по центру.",
    "Поделитесь творением: распечатайте фото или сохраните через QR-код.",
    "Выберите способ сохранения: печать справа или QR-код по центру.",
    "Готово к сохранению! Принтер справа, QR-код по центру.",
    "Сохраните момент: распечатайте фото или отсканируйте QR-код.",
    "Время сохранить шедевр: печать справа, QR-код в центре.",
    "Распечатайте фото справа или получите цифровую копию через QR-код.",
    "Увековечьте момент: кнопка печати справа, QR-код по центру."
]; 