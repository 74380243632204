import React, { useState, useEffect, useRef } from "react";
import { useStyle } from "../../utils/StyleContext";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import "./ImageSelectionScreen.scss";
import StyleGroupsMenu from "../../components/1-ImageSelection/StyleGroupPanel";
import TopMenu from "../../components/1-ImageSelection/TopMenu";
import StyleGrid from "../../components/1-ImageSelection/StyleGrid";
import { usePageTransition } from '../../components/PageTransition';
import logoImage from '../../assets/logo.svg';

const apiServer =
  process.env.REACT_APP_API_SERVER || "https://api.neuro-booth.ru";

const ImageSelectionScreen = () => {
  const { selectedStyle, updateSelectedStyle } = useStyle();
  const { username, stylePreferences, loadStylePreferences } = useUser();
  const [currentVisibleGroup, setCurrentVisibleGroup] = useState("");
  const [genderFilter, setGenderFilter] = useState("all");
  const [visibleGroups, setVisibleGroups] = useState({});
  const [allStyleGroups, setAllStyleGroups] = useState([]);
  const [styleGroups, setStyleGroups] = useState([]);
  const [frameCount, setFrameCount] = useState(0);
  const [scrollToGroup, setScrollToGroup] = useState(null);
  const navigate = useNavigate();
  const groupRefs = useRef({});
  const observerRef = useRef(null);
  const navigateWithTransition = usePageTransition();
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const fetchStylesData = async () => {
      try {
        console.log('Starting fetchStylesData, username:', username);
        
        if (username) {
          console.log('Loading style preferences for user:', username);
          const preferencesResponse = await loadStylePreferences();
          console.log('Style preferences response:', preferencesResponse);
          
          if (preferencesResponse?.status === 401) {
            console.log('Unauthorized response from loadStylePreferences');
            localStorage.clear();
            navigate('/login', { replace: true });
            return;
          }
        }

        const token = localStorage.getItem('token');
        console.log('Token exists:', !!token);
        const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
        
        console.log('Fetching styles from:', `${apiServer}/get-styles`);
        const response = await fetch(`${apiServer}/get-styles`, {
          headers: headers
        });

        console.log('Get styles response status:', response.status);

        if (response.status === 401) {
          localStorage.clear();
          navigate('/login', { replace: true });
          return;
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!Array.isArray(data) || data.length === 0) {
          throw new Error("Invalid data structure");
        }

        const processedData = data.map((group) => ({
          ...group,
          categoryImage: group.styles[0]?.styleImage || null,
        }));

        setAllStyleGroups(processedData);
        localStorage.setItem("styleGroups", JSON.stringify(processedData));

      } catch (error) {
        console.error("Error in fetchStylesData:", error);
        if (error.message?.includes('401')) {
          localStorage.clear();
          navigate('/login', { replace: true });
          return;
        }
        alert(`Произошла ошибка при загрузке стилей: ${error.message}`);
      }
    };

    fetchStylesData();
  }, [username]);

  useEffect(() => {
    if (!allStyleGroups || allStyleGroups.length === 0) return;

    // Don't filter the groups themselves, just mark them as visible or not
    setStyleGroups(allStyleGroups);

    if (selectedStyle && !allStyleGroups.some(group => 
      group.groupName === selectedStyle.groupName && 
      (!stylePreferences || stylePreferences[group.groupName] !== false)
    )) {
      // Find first enabled group
      const firstEnabledGroup = allStyleGroups.find(group => 
        !stylePreferences || stylePreferences[group.groupName] !== false
      );
      if (firstEnabledGroup?.styles?.[0]) {
        selectStyle(firstEnabledGroup.styles[0], firstEnabledGroup.groupName);
      } else {
        updateSelectedStyle(null);
      }
    }
  }, [stylePreferences, allStyleGroups]);

  useEffect(() => {
    const updateFrameCount = () => {
      const count = parseInt(localStorage.getItem("frameCount") || "0");
      if (count !== frameCount) {
        setFrameCount(count);
      }
    };

    updateFrameCount();

    const intervalId = setInterval(updateFrameCount, 1000);

    return () => clearInterval(intervalId);
  }, [frameCount]);

  useEffect(() => {
    console.log("styleGroups updated:", styleGroups);
  }, [styleGroups]);

  useEffect(() => {
    if (!styleGroups || styleGroups.length === 0) return;

    const newVisibleGroups = {};
    styleGroups.forEach((group) => {
      if (group && group.styles) {
        const hasVisibleStyles = group.styles.some(
          (style) =>
            genderFilter === "all" ||
            (style.gender && style.gender.includes(genderFilter))
        );
        const isEnabledInPreferences = !stylePreferences || stylePreferences[group.groupName] !== false;
        
        newVisibleGroups[group.groupName] = hasVisibleStyles && isEnabledInPreferences;
      }
    });
    
    setVisibleGroups(newVisibleGroups);
  }, [genderFilter, styleGroups, stylePreferences, username]);

  const setupIntersectionObserver = () => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentVisibleGroup(entry.target.id);
        }
      });
    }, options);

    Object.values(groupRefs.current).forEach((ref) => {
      if (ref) observerRef.current.observe(ref);
    });
  };

  useEffect(() => {
    setupIntersectionObserver();
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [styleGroups, visibleGroups]);

  const CurrentTimeAndFrameCount = () => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
      const timerId = setInterval(() => setTime(new Date()), 1000);
      return () => clearInterval(timerId);
    }, []);

    const timeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    return (
      <div className="time-and-frame-count">
        <div>{time.toLocaleTimeString("ru-RU", timeFormatOptions)}</div>
        <span className="material-symbols-outlined">add_a_photo</span>{" "}
        {frameCount}
      </div>
    );
  };

  const handleFilterChange = (gender) => {
    setGenderFilter(gender);
  };

  const selectStyle = (style, groupName) => {
    console.log("Selecting style:", style, "from group:", groupName);
    updateSelectedStyle({
      ...style,
      groupName: groupName,
      styleImage: style.styleImage,
    });
    setCurrentVisibleGroup(groupName);
  };

  const goToCameraScreen = () => {
    if (selectedStyle && selectedStyle.prompt) {
      navigateWithTransition('/camera');
    } else {
      alert("Выберите стиль перед переходом!");
    }
  };

  const handleScrollToGroup = (groupName) => {
    setScrollToGroup(groupName);
    setTimeout(() => setScrollToGroup(null), 100);
  };

  const scrollToSelectedStyle = () => {
    console.log("Attempting to scroll to selected style:", selectedStyle);
    if (selectedStyle && selectedStyle.groupName) {
      handleScrollToGroup(selectedStyle.groupName);
    } else {
      console.log("No style is currently selected or missing groupName");
    }
  };

  useEffect(() => {
    if (
      styleGroups.length > 0 &&
      styleGroups[0].styles &&
      styleGroups[0].styles.length > 0
    ) {
      const firstStyle = styleGroups[0].styles[0];
      if (
        firstStyle &&
        firstStyle.styleImage &&
        typeof firstStyle.styleImage === "string"
      ) {
        console.log(
          "Sample image data:",
          firstStyle.styleImage.substring(0, 100) + "...",
        );
      } else {
        console.log("First style image is not a string:", firstStyle);
      }
    } else {
      console.log("No styles available in the first group");
    }
  }, [styleGroups]);

  useEffect(() => {
    console.log("Selected style updated:", selectedStyle);
  }, [selectedStyle]);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((e) => {
        console.error(`Ошибка при переходе в полноэкранный режим: ${e.message}`);
      });
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <div className="app">
      <StyleGroupsMenu
        styleGroups={styleGroups}
        currentVisibleGroup={currentVisibleGroup}
        genderFilter={genderFilter}
        scrollToGroup={handleScrollToGroup}
      />
      <div className="gradient"></div>
      <div className="cards">
        <TopMenu
          genderFilter={genderFilter}
          handleFilterChange={handleFilterChange}
          selectedStyle={selectedStyle}
          scrollToSelectedStyle={scrollToSelectedStyle}
          logoImage={logoImage}
          isFullscreen={isFullscreen}
          toggleFullscreen={toggleFullscreen}
          styleGroups={allStyleGroups}
        />
        {styleGroups.length > 0 && (
          <StyleGrid
            styleGroups={styleGroups}
            genderFilter={genderFilter}
            selectedStyle={selectedStyle}
            selectStyle={selectStyle}
            scrollToGroup={scrollToGroup}
            onNextClick={goToCameraScreen}
            setCurrentVisibleGroup={setCurrentVisibleGroup}
            group={styleGroups[0]}
            onStyleSelect={selectStyle}
            isVisible={true}
          />
        )}
      </div>
    </div>
  );
};

export default ImageSelectionScreen;
