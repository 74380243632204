import React, { useRef, useEffect } from "react";
import { useUser } from "../../context/UserContext";
import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";
import "./Popover.scss";

const Popover = ({ 
  children, 
  isOpen, 
  onClose, 
  displayBalance, 
  onSettingsClick, 
  onLogout, 
  isFullscreen, 
  toggleFullscreen
}) => {
  const { username } = useUser();
  const popoverRef = useRef(null);
  const isClosingRef = useRef(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log('Click event detected:', {
        target: event.target,
        isSettingsButton: event.target.closest('.settings-button'),
        isPopover: event.target.closest('.popover'),
        isClosing: isClosingRef.current
      });

      // Игнорируем клик, если это кнопка настроек или сам попап
      if (event.target.closest('.settings-button') || event.target.closest('.popover')) {
        return;
      }

      // Проверяем, что клик действительно вне попапа
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        if (!isClosingRef.current) {
          isClosingRef.current = true;
          console.log('Closing popover');
          onClose();
          // Сбрасываем флаг через небольшую задержку
          setTimeout(() => {
            isClosingRef.current = false;
          }, 100);
        }
      }
    };

    if (isOpen) {
      // Используем mousedown вместо mouseup
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div 
      className="popover" 
      ref={popoverRef}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="popover-content bubble">
        <ul className="popover-menu">
          <li className="balance-info">
            <div className="balance-text">
              <div className="generation-label">
                <ImageIcon className="image-icon" />
                Генерации
              </div>
              <div className="balance-count">
                <span className="leading-zeros">
                  {Array(7 - displayBalance.toString().length)
                    .fill('0')
                    .join('')}
                </span>
                {displayBalance}
              </div>
            </div>
          </li>
          <li onClick={onSettingsClick}>
            Все настройки
          </li>
          <li onClick={onLogout}>
            Выйти <span className="username">({username})</span>
          </li>
          <li onClick={toggleFullscreen} className="fullscreen-icon">
            <div className="icon-circle">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                {isFullscreen ? (
                  <path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z" fill="currentColor"/>
                ) : (
                  <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" fill="currentColor"/>
                )}
              </svg>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Popover; 