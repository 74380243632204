import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import './RegisterScreen.scss';

const API_BASE_URL = process.env.REACT_APP_API_SERVER;

// Компонент для отображения системных сообщений
const SystemMessage = ({ type, message, onClose }) => {
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        if (onClose) onClose();
      }, 7000);
      return () => clearTimeout(timer);
    }
  }, [message, onClose]);

  if (!message) return null;

  return (
    <div className={`system-message ${type}-message`}>
      <div className="message-content">
        {message}
      </div>
      {onClose && (
        <button className="close-button" onClick={onClose}>
          ×
        </button>
      )}
    </div>
  );
};

// Схема валидации с использованием Yup
const validationSchema = Yup.object().shape({
  login: Yup.string()
    .required('Логин обязателен')
    .min(3, 'Логин должен содержать минимум 3 символа')
    .max(20, 'Логин должен содержать максимум 20 символов')
    .matches(/^[a-zA-Z0-9_]+$/, 'Логин может содержать только буквы, цифры и символ подчеркивания'),
  email: Yup.string()
    .required('Email обязателен')
    .email('Введите корректный email адрес'),
  password: Yup.string()
    .required('Пароль обязателен')
    .min(8, 'Пароль должен содержать минимум 8 символов'),
  confirmPassword: Yup.string()
    .required('Подтверждение пароля обязательно')
    .test('passwords-match', 'Пароли не совпадают', function(value) {
      return value === this.parent.password;
    })
});

const RegisterScreen = () => {
  const [formData, setFormData] = useState({
    login: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({});
  const [systemMessage, setSystemMessage] = useState({ type: '', message: '' });
  const [touchedFields, setTouchedFields] = useState({
    login: false,
    email: false,
    password: false,
    confirmPassword: false
  });
  const navigate = useNavigate();

  // Функция для проверки наличия ошибок в форме
  const hasErrors = () => {
    // Проверяем только ошибки в полях, которые пользователь уже заполнял
    return Object.keys(errors).some(field => 
      touchedFields[field] && errors[field] !== undefined
    );
  };

  // Функция для проверки заполненности всех полей
  const isFormFilled = () => {
    return Object.values(formData).every(value => value.trim() !== '');
  };

  // Функция для определения, должна ли кнопка быть отключена
  const isSubmitDisabled = () => {
    return hasErrors() || !isFormFilled();
  };

  const clearSystemMessage = () => {
    setSystemMessage({ type: '', message: '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {
      ...formData,
      [name]: value
    };
    setFormData(newFormData);
    
    // Отмечаем поле как "тронутое"
    if (!touchedFields[name]) {
      setTouchedFields(prev => ({
        ...prev,
        [name]: true
      }));
    }
    
    // Валидация поля при изменении
    validateField(name, value);
    
    // Если изменяется поле password, нужно также перепроверить confirmPassword
    if (name === 'password' && touchedFields.confirmPassword) {
      validateField('confirmPassword', formData.confirmPassword);
    }
  };
  
  const validateField = async (name, value) => {
    try {
      // Создаем временный объект с текущими значениями формы и обновленным полем
      const fieldSchema = Yup.reach(validationSchema, name);
      
      // Для поля confirmPassword нужно передать весь объект formData для сравнения с password
      if (name === 'confirmPassword') {
        const tempData = { ...formData, [name]: value };
        await validationSchema.validateAt(name, tempData);
      } else {
        await fieldSchema.validate(value);
      }
      
      // Если валидация прошла успешно, удаляем ошибку для этого поля
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    } catch (error) {
      // Если валидация не прошла, устанавливаем сообщение об ошибке
      setErrors(prev => ({
        ...prev,
        [name]: error.message
      }));
    }
  };

  // Валидация всех полей формы
  const validateAllFields = async () => {
    const fieldNames = Object.keys(formData);
    for (const fieldName of fieldNames) {
      await validateField(fieldName, formData[fieldName]);
    }
  };

  // Удаляем проверку всех полей при первоначальной загрузке
  // useEffect(() => {
  //   validateAllFields();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearSystemMessage();

    // Отмечаем все поля как "тронутые" при отправке формы
    const allTouched = Object.keys(touchedFields).reduce((acc, field) => {
      acc[field] = true;
      return acc;
    }, {});
    setTouchedFields(allTouched);

    try {
      // Валидация всей формы перед отправкой
      await validationSchema.validate(formData, { abortEarly: false });
      
      // Если валидация прошла успешно, отправляем данные на сервер
      const response = await fetch(`${API_BASE_URL}/users/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login: formData.login,
          email: formData.email,
          password: formData.password
        })
      });

      // Попытка получить данные ответа
      let data;
      try {
        data = await response.json();
      } catch (e) {
        // Если ответ не содержит JSON
        data = { detail: 'Ошибка при обработке ответа сервера' };
      }

      if (!response.ok) {
        // Извлекаем сообщение об ошибке из ответа
        let errorMessage = 'Ошибка при регистрации';
        
        if (data) {
          if (typeof data === 'string') {
            errorMessage = data;
          } else if (data.detail) {
            errorMessage = data.detail;
          } else if (data.message) {
            errorMessage = data.message;
          } else if (data.error) {
            errorMessage = data.error;
          }
        }
        
        setSystemMessage({ type: 'error', message: errorMessage });
        return;
      }

      setSystemMessage({ 
        type: 'success', 
        message: 'Регистрация успешна! Проверьте email для подтверждения.' 
      });
      
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      if (err.name === 'ValidationError') {
        // Обработка ошибок валидации Yup
        const validationErrors = {};
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
        
        // Показываем общее сообщение об ошибке
        setSystemMessage({ 
          type: 'error', 
          message: 'Пожалуйста, исправьте ошибки в форме' 
        });
      } else {
        // Обработка других ошибок
        setSystemMessage({ type: 'error', message: err.message });
      }
    }
  };

  return (
    <div className="register-screen">
      <div className="register-container">
        <div className="form-side">
          <SystemMessage 
            type={systemMessage.type} 
            message={systemMessage.message} 
            onClose={clearSystemMessage} 
          />
          
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="login"
                placeholder="Логин"
                value={formData.login}
                onChange={handleChange}
                required
                className={touchedFields.login && errors.login ? 'error' : ''}
              />
              {touchedFields.login && errors.login && <div className="input-error-message">{errors.login}</div>}
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Эл. почта"
                value={formData.email}
                onChange={handleChange}
                required
                className={touchedFields.email && errors.email ? 'error' : ''}
              />
              {touchedFields.email && errors.email && <div className="input-error-message">{errors.email}</div>}
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password"
                placeholder="Пароль"
                value={formData.password}
                onChange={handleChange}
                required
                className={touchedFields.password && errors.password ? 'error' : ''}
              />
              {touchedFields.password && errors.password && <div className="input-error-message">{errors.password}</div>}
            </div>
            <div className="form-group">
              <input
                type="password"
                name="confirmPassword"
                placeholder="Подтвердите пароль"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                className={touchedFields.confirmPassword && errors.confirmPassword ? 'error' : ''}
              />
              {touchedFields.confirmPassword && errors.confirmPassword && <div className="input-error-message">{errors.confirmPassword}</div>}
            </div>
            <button type="submit" disabled={isSubmitDisabled()}>Зарегистрироваться</button>
            <div className="forgot-password-link">
              Уже есть аккаунт? <a href="/login">Войти</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen; 