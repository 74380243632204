import React, { createContext, useContext, useState } from 'react';

const API_BASE_URL = process.env.REACT_APP_API_SERVER?.endsWith("/")
  ? process.env.REACT_APP_API_SERVER.slice(0, -1)
  : process.env.REACT_APP_API_SERVER;

const UserContext = createContext();

export const UserProvider = ({ children, initialUsername }) => {
  const [username, setUsername] = useState(initialUsername);
  const [balance, setBalance] = useState(null);
  const [stylePreferences, setStylePreferences] = useState({});

  const updateBalance = async () => {
    try {
      const token = localStorage.getItem('token');
      const username = localStorage.getItem('username');
      
      if (!token || !username) {
        return null;
      }

      const response = await fetch(`${process.env.REACT_APP_API_SERVER}/users/balance/${username}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setBalance(data.balance);
      return data;
    } catch (error) {
      console.error('Error updating balance:', error);
      return null;
    }
  };

  const updateStylePreferences = async (preferences) => {
    try {
      if (!username) return;
      
      const token = localStorage.getItem('token');
      if (!token) return;

      const response = await fetch(`${API_BASE_URL}/users/style-preferences/${username}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ preferences })
      });

      if (!response.ok) throw new Error('Failed to update preferences');
      
      setStylePreferences(preferences);
    } catch (error) {
      console.error('Error updating style preferences:', error);
    }
  };

  const loadStylePreferences = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${API_BASE_URL}/users/style-preferences/${username}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 401) {
        localStorage.removeItem('token');
        throw new Error('Unauthorized');
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setStylePreferences(data);
      return response;
    } catch (error) {
      console.error('Error loading style preferences:', error);
      if (error.message === 'Unauthorized') {
        // Перенаправление на логин будет обработано в компоненте
        return { status: 401 };
      }
      throw error;
    }
  };

  const value = {
    username,
    setUsername,
    balance,
    updateBalance,
    stylePreferences,
    updateStylePreferences,
    loadStylePreferences
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}; 