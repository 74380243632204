import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import './ResetPassword.scss';

const API_BASE_URL = process.env.REACT_APP_API_SERVER;

// Компонент для отображения системных сообщений
const SystemMessage = ({ type, message, onClose }) => {
  if (!message) return null;

  return (
    <div className={`system-message ${type}-message`}>
      <div className="message-content">
        {message}
      </div>
      {onClose && (
        <button className="close-button" onClick={onClose}>
          ×
        </button>
      )}
    </div>
  );
};

// Схема валидации с использованием Yup
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Пароль обязателен')
    .min(8, 'Пароль должен содержать минимум 8 символов')
});

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [systemMessage, setSystemMessage] = useState({ type: '', message: '' });
  const [touchedFields, setTouchedFields] = useState({
    password: false
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const clearSystemMessage = () => {
    setSystemMessage({ type: '', message: '' });
  };

  const validateField = async (name, value) => {
    try {
      await Yup.reach(validationSchema, name).validate(value);
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        [name]: error.message
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    if (!touchedFields[name]) {
      setTouchedFields(prev => ({
        ...prev,
        [name]: true
      }));
    }
    
    validateField(name, value);
  };

  const hasErrors = () => {
    return Object.keys(errors).some(field => 
      touchedFields[field] && errors[field] !== undefined
    );
  };

  const isFormFilled = () => {
    return Object.values(formData).every(value => value.trim() !== '');
  };

  const isSubmitDisabled = () => {
    return hasErrors() || !isFormFilled();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearSystemMessage();

    const allTouched = Object.keys(touchedFields).reduce((acc, field) => {
      acc[field] = true;
      return acc;
    }, {});
    setTouchedFields(allTouched);

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const response = await fetch(`${API_BASE_URL}/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: formData.password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Ошибка при сбросе пароля');
      }

      setSystemMessage({ 
        type: 'success', 
        message: 'Пароль успешно изменен! Перенаправление на страницу входа...' 
      });
      
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      console.error("Reset password error:", err);
      
      if (err.name === 'ValidationError') {
        const validationErrors = {};
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
        
        setSystemMessage({ 
          type: 'error', 
          message: 'Пожалуйста, исправьте ошибки в форме' 
        });
      } else {
        setSystemMessage({ 
          type: 'error', 
          message: err.message || 'Ошибка при сбросе пароля' 
        });
      }
    }
  };

  if (!token) {
    return (
      <div className="reset-password-screen">
        <div className="reset-password-container">
          <SystemMessage 
            type="error" 
            message="Недействительная ссылка для сброса пароля" 
          />
        </div>
      </div>
    );
  }

  return (
    <div className="reset-password-screen">
      <div className="reset-password-container">
        <SystemMessage 
          type={systemMessage.type} 
          message={systemMessage.message} 
          onClose={clearSystemMessage} 
        />
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="password"
              name="password"
              placeholder="Новый пароль"
              value={formData.password}
              onChange={handleChange}
              required
              className={touchedFields.password && errors.password ? 'error' : ''}
            />
            {touchedFields.password && errors.password && <div className="input-error-message">{errors.password}</div>}
          </div>
          <button type="submit" disabled={isSubmitDisabled()}>Сменить пароль</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword; 