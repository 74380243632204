import React, { useState, useEffect } from 'react';
import './MobileOverlay.scss';

const MobileOverlay = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Check initially
    checkIfMobile();

    // Add event listener for window resize
    window.addEventListener('resize', checkIfMobile);

    // Cleanup
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  if (!isMobile) return null;

  return (
    <div className="mobile-overlay">
      <div className="mobile-overlay__content">
        <h2>Мобильная версия недоступна</h2>
        <p>Для работы с приложением, пожалуйста, используйте компьютер или планшет.</p>
      </div>
    </div>
  );
};

export default MobileOverlay; 