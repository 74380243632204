import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ImageSelectionScreen from "./pages/1-ImageSelection/ImageSelectionScreen";
import CameraScreen from "./pages/2-Camera/CameraScreen";
import ResultsScreen from "./pages/3-Result/ResultsScreen";
import { StyleProvider } from "./utils/StyleContext";
import BrowserHistoryController from "./BrowserHistoryController";
import "./styles/App.scss";
import { PageTransition } from './components/PageTransition';
import LoadingProgress from './components/LoadingProgress/LoadingProgress';
import LoginScreen from "./pages/Login/LoginScreen";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserProvider } from './context/UserContext';
import RegisterScreen from './pages/Register/RegisterScreen';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import EmailConfirmation from './pages/EmailConfirmation/EmailConfirmation';
import MobileOverlay from './components/MobileOverlay/MobileOverlay';

const App = () => {
  const storedUsername = localStorage.getItem('username');

  return (
    <UserProvider initialUsername={storedUsername}>
      <LoadingProgress />
      <StyleProvider>
        <Router>
          <BrowserHistoryController />
          <div className="App">
            <Routes>
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/" element={
                <ProtectedRoute>
                  <ImageSelectionScreen />
                </ProtectedRoute>
              } />
              <Route path="/camera" element={
                <ProtectedRoute>
                  <CameraScreen />
                </ProtectedRoute>
              } />
              <Route path="/results" element={
                <ProtectedRoute>
                  <ResultsScreen />
                </ProtectedRoute>
              } />
              <Route path="/register" element={<RegisterScreen />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/verify-email/:token" element={<EmailConfirmation />} />
            </Routes>
          </div>
          <PageTransition />
          <MobileOverlay />
        </Router>
      </StyleProvider>
    </UserProvider>
  );
};

export default App;
