import React, { useState } from 'react';
import * as Yup from 'yup';
import './ForgotPassword.scss';

const API_BASE_URL = process.env.REACT_APP_API_SERVER;

// Компонент для отображения системных сообщений
const SystemMessage = ({ type, message, onClose }) => {
  if (!message) return null;

  return (
    <div className={`system-message ${type}-message`}>
      <div className="message-content">
        {message}
      </div>
      {onClose && (
        <button className="close-button" onClick={onClose}>
          ×
        </button>
      )}
    </div>
  );
};

// Схема валидации с использованием Yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email обязателен')
    .email('Введите корректный email адрес')
});

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [systemMessage, setSystemMessage] = useState({ type: '', message: '' });
  const [touched, setTouched] = useState(false);

  const clearSystemMessage = () => {
    setSystemMessage({ type: '', message: '' });
  };

  const validateField = async (value) => {
    try {
      await validationSchema.validate({ email: value }, { abortEarly: false });
      setErrors({});
      return true;
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach(err => {
        validationErrors.email = err.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleChange = async (e) => {
    const value = e.target.value;
    setEmail(value);
    setTouched(true);
    await validateField(value);
  };

  const isSubmitDisabled = () => {
    return (touched && errors.email) || !email.trim();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearSystemMessage();
    
    // Валидация перед отправкой
    const isValid = await validateField(email);
    if (!isValid) {
      setSystemMessage({ 
        type: 'error', 
        message: 'Пожалуйста, исправьте ошибки в форме' 
      });
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Ошибка при отправке запроса');
      }

      setSystemMessage({ 
        type: 'success', 
        message: 'Если указанный email зарегистрирован в системе, вы получите инструкции по восстановлению пароля.' 
      });
    } catch (err) {
      setSystemMessage({ 
        type: 'error', 
        message: err.message 
      });
    }
  };

  return (
    <div className="forgot-password-screen">
      <div className="forgot-password-container">
        <SystemMessage 
          type={systemMessage.type} 
          message={systemMessage.message} 
          onClose={clearSystemMessage} 
        />
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              placeholder="Введите вашу почту"
              value={email}
              onChange={handleChange}
              required
              className={touched && errors.email ? 'error' : ''}
            />
            {touched && errors.email && <div className="input-error-message">{errors.email}</div>}
          </div>
          <button type="submit" disabled={isSubmitDisabled()}>Отправить</button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword; 