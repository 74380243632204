import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './EmailConfirmation.scss';

const API_BASE_URL = process.env.REACT_APP_API_SERVER;

const EmailConfirmation = () => {
  const [status, setStatus] = useState('loading'); // loading, success, error
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyEmail = async () => {
      if (!token) {
        setStatus('error');
        setMessage('Недействительный токен подтверждения');
        return;
      }

      try {
        // Using a custom fetch that doesn't follow redirects
        const response = await fetch(`${API_BASE_URL}/verify-email/${token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'manual' // Don't follow redirects
        });

        // Check if the response is a redirect (status 3xx)
        if (response.status >= 300 && response.status < 400) {
          // Handle as success even if it's a redirect
          setStatus('success');
          setMessage('Email успешно подтвержден! Ваш аккаунт активирован и на баланс начислено 10 генераций.');
          return;
        }

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Ошибка при подтверждении email');
        }

        setStatus('success');
        setMessage('Email успешно подтвержден! Ваш аккаунт активирован и на баланс начислено 10 генераций.');
      } catch (err) {
        // If the error is due to a redirect, treat it as success
        if (err.name === 'TypeError' && err.message.includes('redirect')) {
          setStatus('success');
          setMessage('Email успешно подтвержден! Ваш аккаунт активирован и на баланс начислено 10 генераций.');
          return;
        }
        
        setStatus('error');
        setMessage(err.message || 'Ошибка при подтверждении email');
      }
    };

    verifyEmail();
  }, [token]);

  const handleRedirect = () => {
    navigate('/login');
  };

  return (
    <div className="email-confirmation-screen">
      <div className="email-confirmation-container">
        <div className="form-side">
          {status === 'loading' && (
            <div className="loading-message">Подтверждение email...</div>
          )}
          
          {status === 'error' && (
            <div className="error-message">{message}</div>
          )}
          
          {status === 'success' && (
            <div className="success-message">{message}</div>
          )}
          
          <div className="action-buttons">
            {status !== 'loading' && (
              <button onClick={handleRedirect}>
                {status === 'success' ? 'Перейти к входу' : 'Вернуться на главную'}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation; 