import React from 'react';
import './StyleToggle.css';

const StyleToggle = ({ groupName, isEnabled, onToggle, styles, categoryImage }) => {
  // Получаем изображение либо из categoryImage, либо случайное из массива стилей
  const getImageUrl = () => {
    if (!isEnabled) return categoryImage; // Не меняем изображение, если тогл выключен
    if (categoryImage) return categoryImage;
    if (!styles || styles.length === 0) return null;
    const randomIndex = Math.floor(Math.random() * styles.length);
    return styles[randomIndex].styleImage;
  };

  const imageUrl = getImageUrl();
  
  return (
    <div className="style-group-toggle">
      <div className="toggle-container">
        {/* {imageUrl && (
          <img 
            src={imageUrl} 
            className="style-icon" 
            alt={groupName}
            onError={(e) => {
              console.warn(`Failed to load image for style: ${groupName}, URL: ${imageUrl}`);
              e.target.style.display = 'none';
            }} 
          />
        )} */}
        <span className="style-name">{groupName}</span>
        <label className="switch">
          <input
            type="checkbox"
            checked={isEnabled}
            onChange={() => onToggle(groupName)}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default StyleToggle;